/* index.css */

/* Universal styles for body, headings, paragraphs, and lists */
body {
    font-family: Arial, sans-serif;
    margin: 20px;
    line-height: 1.6;
    color: #555555;
  }
  
  h1 {
    text-align: left;
    color: #333333;
    margin-bottom: 10px;
  }
  
  h3 {
    text-align: left;
    color: #333333;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  p {
    text-align: left;
    color: #555555;
  }
  
  ul {
    text-align: left;
    color: #555555;
    list-style-type: disc;
    margin-left: 20px;
  }
  
  /* Style for links */
  a {
    color: #007BFF;
    text-decoration: none;
    font-size: 18px;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Example container styling for project sections */
  .project-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  